import './App.css';
import MainContent from './Components/mainContent';
import Navbar from './Components/navbar';
import { Box, Dialog, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useRef, useState } from 'react';

function App() {
  const raiRef = useRef(null);
    const productRef = useRef(null);
    const solutionRef = useRef(null);
    const softwareRef = useRef(null);
    const aiRef = useRef(null);
    const cloudRef = useRef(null);
    const [openContactDialog, setOpenContactDialog] = useState(false);

  return (
    <Box bgcolor={"background.default"} color={"text.primary"}>
      <Navbar
      raiRef={raiRef}
      softwareRef={softwareRef}
      aiRef={aiRef}
      cloudRef={cloudRef}
      productRef={productRef}
      solutionRef={solutionRef}
      setOpenContactDialog={setOpenContactDialog}
      />
      <MainContent 
      raiRef={raiRef}
      softwareRef={softwareRef}
      aiRef={aiRef}
      cloudRef={cloudRef}
      productRef={productRef}
      solutionRef={solutionRef}
      />
      <Dialog
      open={openContactDialog}
      >
        <DialogContent style={{backgroundColor: "black"}}>
        <Typography style={{ 
          height: "300px", 
          width: "400px",
          padding: "20px",
          fontSize: "20px",
          color: "white"
          }}>
          <div style={{fontWeight: "700", fontSize: "40px", marginBottom: "20px"}}>Connect With Us:</div>
          From Pune, Hyderabad, Bangalore in India
          <br></br>
          Serving Clients Worldwide
          <div style={{display: "flex", flexDirection: "column", marginTop: "20px"}}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "10px"}}>
              Website
              <span>www.noaxs.in</span>
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between",  marginBottom: "10px"}}>
              Email
              <span>info@noaxs.ai</span>
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between",  marginBottom: "10px"}}>
              Phone
              <span>+1 (309) 439 6600
                <br></br>
                +91 91219 13795</span>
            </div>
          </div>
        </Typography>
        </DialogContent>
        <DialogActions style={{backgroundColor: "black"}}>
          <Button onClick={() => { setOpenContactDialog(false) }} autoFocus
          style={{
            marginRight: "30px",
            fontSize: "20px",
            backgroundColor: "grey",
            marginBottom: "20px"
          }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default App;
