import { Box, Button, Typography } from "@mui/material";
import devopsBg from "../../images/devopsBg.png";
import CloudIcon from '@mui/icons-material/Cloud';
import styled from "@emotion/styled";

const CloudContent = () => {

    const ContentBox = styled(Box)(({theme}) => ({
        textTransform: 'none',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "30px",
        borderRadius: "30px",
        boxShadow: "0 4px 8px black",
        backgroundColor: "white",
        color: "#004d61",
        width: "800px",
        height: "300px",
        [theme.breakpoints.up('md')]: {
            flexDirection: "row",
            marginRight: "30px",
            marginLeft: "30px"
        },
    }));

    const ContentDiv = styled('div')(({theme}) => ({
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up('md')]: {
            flexDirection: "row",

        },
    }));
    return (
        <div>
            <ContentDiv id="rai-content-box-1">
            <ContentBox style={{ height: "400px", width: "600px"}}>
            <Box
        id="Rai"
        sx={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            scrollMarginTop: "70px",
        }}>
            <Typography style={{
                padding: "10px",
                fontSize: "70px",
                fontWeight: "700",
                display: "flex",
                textAlign: "center",
                color: "#004d61",
                lineHeight: "80px"
            }}>
                <div>
                    <CloudIcon style={{width: "80px", height: "80px", marginBottom: "-10px", marginRight: "20px"}} />
                Cloud
                <br></br>
                & Devops
                </div>
            </Typography>
        </Box>
            </ContentBox>
            <ContentBox style={{ marginTop: "50px"}}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "90vh",
                    textAlign: "center",
                }}>
                <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                }}
                >
            <Typography sx={{
                    fontSize: "24px",
                    fontWeight: "700",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "20px",
                    lineHeight: "40px"
                }}
                >
                <div sx={{ display: "flex", alignItems: "center" }}>
                We empower your business with state-of-the-art Cloud
                and DevOps services, ensuring robust, scalable, and
                efficient technological environments.
                <br></br>
                </div>
                </Typography>
                </div>
            </div>
            </ContentBox>
            <div style={{ height: "320px", width: "800px", display: "flex", justifyContent: "center"}}>
                <div style={{
                    marginTop: "50px",
                    backgroundImage: `url(${devopsBg})`,
                    height: "320px",
                    width: "500px"
                }}>

                </div>
            </div>
            </ContentDiv>
            <ContentDiv>
                <ContentBox style={{width: "850px", height: "380px"}}>
                    <Typography style={{display: "flex", flexDirection: "column", padding: "20px", fontSize: "19px", fontWeight: "500"}}>
                    <span style={{fontWeight: "700", fontSize: "20px"}}>Cloud ETL Expertise</span>
                    We specialize in data extraction, pulling from diverse sources to ensure a rich
                    data landscape. Our data transformation capabilities allow us to clean, normalize,
                    and enrich your data, making it more useful and insightful for your business
                    needs. Finally, with our data loading techniques, we ensure that your data is
                    efficiently moved into systems like BigQuery, enabling advanced analytics and
                    business intelligence capabilities.
                    <br></br>
                    <br></br>
                    <span style={{fontWeight: "700", fontSize: "20px"}}>Key Features:</span>
                    <li>Comprehensive data handling from extraction to loading</li>
                    <li>Enhancements with Apache Beam for precise data transformation</li>
                    <li>Integration with GCP services like BigQuery and Data Studio for robust analytics</li>
                    </Typography>
                </ContentBox>
                <ContentBox style={{width: "850px", height: "380px"}}>
                <Typography style={{display: "flex", flexDirection: "column", padding: "20px", fontSize: "18px", fontWeight: "500"}}>
                    <span style={{fontWeight: "700", fontSize: "20px"}}>DevOps Services</span>
                    Optimize your software delivery with our expert DevOps services. We focus on
                    CI/CD pipeline optimization to automate your development and deployment
                    processes, enhancing both speed and reliability. Our cloud resource management
                    strategies ensure that your cloud environments are not only cost-effective but
                    also scaled appropriately to meet demand. With containerization using Docker
                    and Kubernetes, we enhance application portability and scalability, supporting
                    your growth seamlessly across platforms.
                    <br></br>
                    <br></br>
                    <span style={{fontWeight: "700", fontSize: "20px"}}>Key Features:</span>
                    <li>Automation of build, test, and deployment processes</li>
                    <li>Efficient management and optimization of cloud resources</li>
                    <li>Implementation of container technologies for improved scalability</li>
                    </Typography>
                </ContentBox>
            </ContentDiv>
        </div>
    )
};

export default CloudContent;