import React from "react";
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import ScaleOutlinedIcon from '@mui/icons-material/ScaleOutlined';
import FlashOffOutlinedIcon from '@mui/icons-material/FlashOffOutlined';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import SouthIcon from '@mui/icons-material/South';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import SmartOutdoorBg from '../../images/smartSolutionBg.png'

const SmartOutdoorSolution = () => {
    const localStyles = {
        container: {
            display: "flex",
            flexDirection: "row",
            marginBottom: "180px"
        },
        divContainer: {
            marginLeft: "30px",
        },
        heading: {
            marginTop: "15px",
            marginBottom: "5px",
            fontSize: "20px",
            fontWeight: "700",
        },
        drawbackContainer: {
            display: "flex",
            flexDirection: "column"
        },
        drawbackHeadings: {
            fontSize: "16px",
            fontWeight: "600",
            marginBottom: "5px"
        },
        drawbackIcons: {
            color: "red",
            width: "50px",
            height: "50px",
            display: "flex",
            alignItems: "center",
            marginRight: "15px",
        },
        solutionHeading: {
            fontSize: "40px",
            fontWeight: "700",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            lineHeight: "55px",
            color: "green",
            height: "340px", 
            width: "700px",
            backgroundColor: "#F8F8FF"
        },
        solutionContent: {
            margin: "10px 10px 10px 10px",
            textAlign: "flex-start",
            fontSize: "25px"
        }
    }
    const ContentBox = styled(Box)(({theme}) => ({
        textTransform: 'none',
        display: "flex",
        justifyContent: "flex-start",
        // alignItems: "center",
        marginBottom: "30px",
        borderRadius: "30px",
        boxShadow: "0 4px 8px black",
        backgroundColor: "#FFFFFF",
        color: "black",
        width: "50%",
        // height: "150px",
        [theme.breakpoints.up('md')]: {
            flexDirection: "column",
            width: "50%",
            marginRight: "30px",
            marginLeft: "30px",
            //height: "540px",
        },
    }));
    const ContentBoxTwo = styled(Box)(({theme}) => ({
        textTransform: 'none',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "30px",
        backgroundColor: "#FFFFFF",
        color: "black",
        width: "50%",
        [theme.breakpoints.up('md')]: {
            flexDirection: "column",
            width: "50%",
            marginRight: "30px",
            marginLeft: "30px",
        },
    }));

    const SolutionContentBox = styled(Box)(({theme}) => ({
        textTransform: 'none',
        display: "flex",
        justifyContent: "flex-start",
        // alignItems: "center",
        marginBottom: "30px",
        backgroundColor: "#FFFFFF",
        color: "black",
        width: "90%",
        // height: "150px",
        [theme.breakpoints.up('md')]: {
            flexDirection: "column",
            width: "98%",
            marginRight: "30px",
            marginLeft: "30px",
            //height: "540px",
        },
    }));
    

    const SolutionBox = styled(Box)(({theme}) => ({
        textTransform: 'none',
        display: "flex",
        //flexDirection: "row",
        justifyContent: "flex-start",
        // alignItems: "center",
        marginRight: "8px",
        border: "1px solid black",
        boxShadow: "0 4px 8px black",
        backgroundColor: "#FFFFFF",
        color: "black",
        width: "50%",
        //marginTop: "80px",
        padding: "10px, 10px, 10px, 10px",
        height: "240px"
    }));

    const BenefitsBox = styled(Box)(({theme}) => ({
        textTransform: 'none',
        display: "flex",
        //flexDirection: "row",
        justifyContent: "flex-start",
        // alignItems: "center",
        width: "90%",
        height: "200px",
        marginRight: "8px",
        border: "1px solid black",
        boxShadow: "0 4px 8px black",
        backgroundColor: "#FFFFFF",
        color: "black",
        marginBottom: "20px",
        // marginTop: "80px",
        padding: "10px, 10px, 10px, 10px"
    }));

    const DrawbackBox = styled(Box)(({theme}) => ({
        //height: "30px",
        width: "95%",
        display: "flex",
        flexDirection: "row",
        fontSize: "15px",
        marginBottom: "20px",
    }));

    
    return (
        <div>
            <div style={localStyles.container}>
                <ContentBox style={{backgroundColor: "#F8F8FF", height: "540px",
            display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "700",
                fontSize: "60px",
                marginBottom: "50px",
                scrollMarginTop: "100px",
                backgroundColor: "#F8F8FF",
                textAlign: "center",
            }}>
            {/* // ref={smartRef} */}
                <Typography style={{color: "#000073", fontSize: "60px", fontWeight: "700"}}>
                Smart Outdoor Power Management
                </Typography>
            </div>
                </ContentBox>
                <ContentBox style={{backgroundColor: "#fedcda", height: "400px", marginTop: "60px"}}>
                    <div style={localStyles.divContainer}>
                    <Typography style={localStyles.heading}>
                    Challenges with Current Preset Timer Systems
                    </Typography>
                    <Typography style={{
                        fontSize: "15px", 
                        marginBottom: "15px",
                        paddingRight: "15px"}}>
                    In our industrial campus parking spaces, high mast 
                    lamps operate based on preset timer settings for each lamp post. However, 
                    this system presents several challenges:
                    </Typography>
                    <div style={localStyles.drawbackContainer}>
                        <DrawbackBox>
                            <TimerOutlinedIcon style={localStyles.drawbackIcons}/>
                            <div style={{display: "flex", flexDirection: "column"}}>
                            <span style={localStyles.drawbackHeadings}>Limited Flexibility:</span>
                            The fixed schedules of preset timers restrict adaptability to changing environmental conditions.
                            </div>
                        </DrawbackBox>
                        <DrawbackBox>
                            <ScaleOutlinedIcon style={localStyles.drawbackIcons}/>
                            <div style={{display: "flex", flexDirection: "column"}}>
                            <span style={localStyles.drawbackHeadings}>Real-Time Adjustment Issues:</span>
                            The inability to adjust settings in real-time leads to suboptimal energy usage during off-peak 
                            hours or unexpected events.The fixed schedules of preset timers restrict adaptability to changing 
                            environmental conditions.
                            </div>
                        </DrawbackBox>
                        <DrawbackBox>
                            <FlashOffOutlinedIcon style={localStyles.drawbackIcons}/>
                            <div style={{display: "flex", flexDirection: "column"}}>
                            <span style={localStyles.drawbackHeadings}>Lack of Responsiveness:</span>
                            Current systems are not responsive to dynamic needs, resulting in inefficiencies and potential energy wastage.
                            </div>
                        </DrawbackBox>
                    </div>
                    </div>
                </ContentBox>
                <ContentBoxTwo style={{ height: "400px", marginTop: "60px"}}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                    <TipsAndUpdatesIcon style={{ height: "80px", width: "80px", color: "green",  marginRight: "20px"}}/>
                    <Typography style={{ fontSize: "30px", fontWeight: "700", paddingTop: "15px"}}>Relax, we’ve taken care of everything</Typography>
                    </div>
                    <SouthIcon style={{ height: "80px", width: "80px", color: "green", marginLeft: "20px"}}/>
                </ContentBoxTwo>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "80px"
            }}>
                <div style={{ 
                    display: "flex",
                    flexDirection: "column"
            }}>
                <SolutionContentBox style={{ display: "flex", flexDirection: "column"}}>
                    <div style={{ display: "flex", flexDirection: "row", marginTop: "20px"}}>
                    <SolutionBox style={localStyles.solutionHeading}>
                    Introducing the New Solution: <span style={{fontSize: "35px", color: "green"}}>Smart Outdoor Power Management</span>
                    </SolutionBox>
                    <SolutionBox style={{backgroundColor: "#d1e8fc", marginTop: "50px"}}>
                        <Typography style={localStyles.solutionContent}>
                        <li>Nodes with long-range communication installed on lamp posts allow 
                        you to remotely control and operate the high mast lamps from a distance.</li>
                        </Typography>
                    </SolutionBox>
                    <SolutionBox style={{backgroundColor: "#fedcdb", marginTop: "50px"}}>
                    <Typography style={localStyles.solutionContent}>
                        <li>Our centrally hosted web application provides visualization, 
                            real-time monitoring, and on-demand control of the entire lighting system. 
                            </li>
                            </Typography>
                    </SolutionBox>
                    <SolutionBox style={{backgroundColor: "#d0c9ea", marginTop: "50px"}}>
                    <Typography style={localStyles.solutionContent}>
                        <li>
                        The Central Gateway device ensures smooth data transmission, making it easy to 
                        control and monitor the system efficiently.
                        </li>
                        </Typography>
                    </SolutionBox>
                    </div>
                </SolutionContentBox>
                <SolutionContentBox style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                <BenefitsBox style={{backgroundColor: "#d7f5d3"}}>
                        <Typography style={localStyles.solutionContent}>
                            <div style={{ fontSize: "20px", fontWeight: "700", textAlign: "center", marginBottom: "10px"}}>Operational Benefits</div>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <div style={{display: "flex", flexDirection: "row", marginBottom: "10px"}}>
                                <DoneAllOutlinedIcon style={{color: "green", marginRight: "5px",}}/>
                                <Typography>
                                <strong>Instant Adjustments:</strong> You can change lighting levels immediately, solving the issue of fixed schedules.
                                </Typography>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", marginBottom: "10px"}}>
                                <DoneAllOutlinedIcon style={{color: "green", marginRight: "5px",}}/>
                                <Typography>
                                <strong>Greater Flexibility:</strong> The system adapts to changing needs, unlike the current static setup.
                                </Typography>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", marginBottom: "10px"}}>
                                <DoneAllOutlinedIcon style={{color: "green", marginRight: "5px",}}/>
                                <Typography>
                                <strong>Better Energy Efficiency:</strong> Precise control reduces energy waste and optimizes usage.
                                </Typography>
                            </div>
                        </div>
                        </Typography>
                    </BenefitsBox>
                    <BenefitsBox style={{backgroundColor: "#d7f5d3"}}>
                        <Typography style={localStyles.solutionContent}>
                            <div style={{ fontSize: "20px", fontWeight: "700", textAlign: "center", marginBottom: "10px"}}>Economical Benefits</div>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <div style={{display: "flex", flexDirection: "row", marginBottom: "10px"}}>
                                <DoneAllOutlinedIcon style={{color: "green", marginRight: "5px",}}/>
                                <Typography>
                                <strong>Save Money:</strong> On-demand remote control of lamps cuts energy costs.
                                </Typography>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", marginBottom: "10px"}}>
                                <DoneAllOutlinedIcon style={{color: "green", marginRight: "5px",}}/>
                                <Typography>
                                <strong>Less Maintenance:</strong> Remote monitoring helps prevent issues and reduces upkeep.
                                </Typography>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", marginBottom: "10px"}}>
                                <DoneAllOutlinedIcon style={{color: "green", marginRight: "5px",}}/>
                                <Typography>
                                <strong>Future-Ready:</strong> Easily scalable and can integrate with smart city projects for better efficiency and sustainability.
                                </Typography>
                            </div>
                        </div>
                        </Typography>
                    </BenefitsBox>
                </SolutionContentBox>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "98%"}}>
            <Box sx={{ backgroundImage: `url(${SmartOutdoorBg})`, height: "150px", width: "550px", marginRight: "180px", marginLeft: "180px"}}/>
                <BenefitsBox style={{ display: "flex", justifyContent: "center", textAlign: "center", backgroundColor: "#FDFDD2", 
                height: "100px", width: "50%"}}>
                    <Typography style={{ textAlign: "center", fontSize: "24px"}}>
                    Contact us to explore how our solution can add <strong>Smart</strong> to your outdoor lighting management. 
                    <span style={{color: "green", fontWeight: "700"}}> Let us illuminate future together!</span>
                    </Typography>
                </BenefitsBox>
            </div>
        </div>
    )

};

export default SmartOutdoorSolution;