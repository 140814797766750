import { Box, Button, Typography, styled
 } from "@mui/material";
import React, { useRef } from "react";
import RaiContent from "./RaiContent/raiContent";
import aiBg from "../images/aiBg.png";
import cloudBg from "../images/cloudBg.jpg";
import ProductEngineeringContent from "./productEngineeringContent";
import EngineeringBg from "../images/EngineeringBg.jpg"
import SolutionsContent from "./solutions/solutionsContent";
import SdeContent from "./SoftwareDevelopment/SdeContent";
import AiIntegrationContent from "./AiIntegrations/aiIntegrationContent";
import CloudContent from "./CloudAndDevops/cloudContent";
import developmentBg from "../images/developmentBg.jpg";
import raiBg from "../images/raiBg.jpg";

const MainContent = ({ raiRef, softwareRef, aiRef, cloudRef, productRef, solutionRef}) => {

    const scrollToRef = (ref) => ref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

    const StyledBox = styled(Button)(({theme}) => ({
        textTransform: 'none',
        width: "600px",
        height: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "30px",
        borderRadius: "30px",
        boxShadow: "0 4px 8px #800020",
        '&:hover': {
            backgroundColor: 'lightblue', // Change this to your desired hover color
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: "row",
            width: "700px",
            height: "300px",
            marginRight: "30px",
            marginTop: "60px",
            marginLeft: "30px",
            marginBottom: "30px"
        },
    }));

    const MainBox = styled(Box)(({theme}) => ({
        backgroundColor: theme.palette.primary.main,
        height: "60%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        [theme.breakpoints.up('md')]: {
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
        },
    }));

    const BoxContent = styled(Typography)(({theme}) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        color: theme.palette.otherColor.main,
        [theme.breakpoints.up('md')]: {
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
    }));

    const RaiBox = styled(Box)(({theme}) => ({
        backgroundColor: theme.palette.primary.main,
        height: "60%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    }));
    return (
        <div>
            <div style={{display: "flex", flexDirection: "column", marginTop: "50px"}}>
        <MainBox>
            <StyledBox sx={{ 
                    marginTop: { 
                        sm: "30px", 
                        s: "30px", 
                        md: "60px", 
                        lg: "60px" 
                        },
                    backgroundColor: "#f2f4fa",
                }}
                onClick={ () => scrollToRef(raiRef) }>
                <BoxContent style={{ fontSize: "80px"}}>
                    RAi
                </BoxContent>
            </StyledBox>
            <StyledBox sx={{ backgroundColor: "#f2f4fa" }}
            onClick={ () => scrollToRef(softwareRef) }>
                <BoxContent style={{ fontSize: "80px", margin: "0px", lineHeight: "90px"}}>
                    Software Development
                </BoxContent>
            </StyledBox>
            <StyledBox sx={{ backgroundColor: "#f2f4fa" }}
            onClick={ () => scrollToRef(aiRef) }>
                <BoxContent style={{ fontSize: "80px", margin: "0px", lineHeight: "90px"}}>
                    AI 
                    <br></br>
                    Integrations
                </BoxContent>
            </StyledBox>
            </MainBox>
            <MainBox>
            <StyledBox sx={{ backgroundColor: "#f2f4fa" }}
            onClick={ () => scrollToRef(cloudRef) }>
                <BoxContent style={{ fontSize: "80px", margin: "0px", lineHeight: "90px"}}>
                    Cloud and DevOPs
                </BoxContent>
            </StyledBox>
            <StyledBox sx={{ backgroundColor: "#f2f4fa" }}
            onClick={ () => scrollToRef(productRef) }>
                <BoxContent style={{ fontSize: "80px", margin: "0px", lineHeight: "90px"}}>
                    Product Engineering
                </BoxContent>
            </StyledBox>
            <StyledBox sx={{ backgroundColor: "#f2f4fa", }}
            onClick={ () => scrollToRef(solutionRef) }>
            <BoxContent style={{ fontSize: "80px"}}>
                    Solutions
                </BoxContent>
            </StyledBox>
        </MainBox>
        </div>
        <RaiBox ref={ raiRef } style={{ marginTop: "50px", scrollMarginTop: "104px", backgroundImage: `url(${raiBg})`}}>
        <Box sx={{
                width: "90%",
                marginY: "20px",
            }}>
                <RaiContent />
            </Box>
        </RaiBox>
        <RaiBox ref={ softwareRef } style={{ scrollMarginTop: "100px", backgroundColor: "#15647f", backgroundImage: `url(${developmentBg})`}}>
        <Box sx={{
                width: "90%",
                marginY: "20px",
            }}>
                <SdeContent />
            </Box>
        </RaiBox>
        <RaiBox ref={ aiRef } style={{ scrollMarginTop: "100px", backgroundImage: `url(${aiBg})`}}>
        <Box sx={{
                width: "90%",
                marginY: "20px",
            }}>
                <AiIntegrationContent />
            </Box>
        </RaiBox>
        <RaiBox ref={ cloudRef } style={{ scrollMarginTop: "100px", backgroundImage: `url(${cloudBg})`}}>
        <Box sx={{
                width: "90%",
                marginY: "20px",
            }}>
                <CloudContent />
            </Box>
        </RaiBox>
        <RaiBox>
        <Box sx={{
                width: "100%",
                backgroundColor: "White",
                backgroundImage: `url(${EngineeringBg})`,
                scrollMarginTop: "100px",
                height: "860px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
            ref={ productRef }
            >
                <ProductEngineeringContent />
            </Box>
        </RaiBox>
        <RaiBox ref={ solutionRef } style={{ marginTop: "50px", marginBottom: "50px", scrollMarginTop: "140px",}}>
        <Box sx={{
                width: "90%",
                marginY: "20px",
            }}>
                <SolutionsContent />
            </Box>
        </RaiBox>
        </div>
    )
};

export default MainContent;