import { Box, Button, Typography } from "@mui/material";
import TerminalIcon from '@mui/icons-material/Terminal';
import styled from "@emotion/styled";
import softwareBg from "../../images/softwareBg.png"

const SdeContent = () => {

    const ContentBox = styled(Box)(({theme}) => ({
        textTransform: 'none',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "30px",
        borderRadius: "30px",
        boxShadow: "0 4px 8px black",
        backgroundColor: "white",
        //color: "#4c0212",
        color: "#524581",
        width: "800px",
        height: "300px",
        [theme.breakpoints.up('md')]: {
            flexDirection: "row",
            marginRight: "30px",
            marginLeft: "30px"
        },
    }));

    const ContentDiv = styled('div')(({theme}) => ({
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up('md')]: {
            flexDirection: "row",

        },
    }));
    return (
        <div>
            <ContentDiv id="rai-content-box-1">
            <ContentBox style={{ height: "400px", width: "600px"}}>
            <Box
        id="Rai"
        sx={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            scrollMarginTop: "70px",
            width: "500px"
        }}>
            <Typography style={{
                padding: "10px",
                fontSize: "75px",
                fontWeight: "700",
                display: "flex",
                textAlign: "center",
                color: "#524581"
            }}>
                <div>
                <TerminalIcon style={{width: "100px", height: "100px", marginBottom: "-20px", marginRight: "20px"}} />
                Software Development
                </div>
            </Typography>
        </Box>
            </ContentBox>
            <ContentBox style={{ marginTop: "50px"}}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "90vh",
                    textAlign: "center",
                }}>
                <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                }}
                >
            <Typography sx={{
                    fontSize: "24px",
                    fontWeight: "700",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "20px",
                    lineHeight: "40px"
                }}
                >
                <div sx={{ display: "flex", alignItems: "center" }}>
                We blend technical mastery with innovative solutions to
                elevate your business. 
                <br></br>
                Our team specializes in Backend,
                Frontend, and Full Stack Development, utilizing a robust
                stack of modern technologies
                </div>
                </Typography>
                </div>
            </div>
            </ContentBox>
            <div style={{ height: "300px", width: "800px", display: "flex", justifyContent: "center"}}>
                <div style={{
                    backgroundImage: `url(${softwareBg})`,
                    height: "400px",
                    width: "300px"
                }}>

                </div>
            </div>
            </ContentDiv>
            <ContentDiv>
                <ContentBox style={{width: "850px", height: "380px"}}>
                    <Typography style={{display: "flex", flexDirection: "column", padding: "20px", fontSize: "16px", fontWeight: "500"}}>
                    <span style={{fontWeight: "700", fontSize: "18px"}}>Backend Development</span>
                    Our expertise in .NET Core and Entity Framework allows us to build highly
                    efficient and scalable systems tailored to your business needs. We also specialize
                    in Python, utilizing its versatility to develop dynamic software applications that
                    enhance backend functionalities and streamline processes.
                    <br></br>
                    <span style={{fontWeight: "700", fontSize: "18px"}}>Key Technologies:</span>
                    <li>.NET Core</li>
                    <li>Entity Framework</li>
                    <li>Python (Flask, Django, FastAPI)</li>
                    <span style={{fontWeight: "700", fontSize: "18px"}}>Our Expertise Includes:</span>
                    <li>Developing RESTful APIs and microservices</li>
                    <li>Seamless integration with third-party services</li>
                    <li>Optimizing performance and scalability</li>
                    </Typography>
                </ContentBox>
                <ContentBox style={{width: "850px", height: "380px"}}>
                <Typography style={{display: "flex", flexDirection: "column", padding: "20px", fontSize: "16px", fontWeight: "500"}}>
                    <span style={{fontWeight: "700", fontSize: "18px"}}>Frontend Development</span>
                    Our developers are skilled in Vue.js and React.js, creating rich, interactive user
                    interfaces that not only look good but are intuitive and user-friendly
                    <br></br>
                    <br></br>
                    <span style={{fontWeight: "700", fontSize: "18px"}}>Key Technologies:</span>
                    <li>Vue.js</li>
                    <li>React.js</li>
                    <br></br>
                    <span style={{fontWeight: "700", fontSize: "18px"}}>Our Expertise Includes:</span>
                    <li>Crafting engaging and visually appealing web applications</li>
                    <li>Ensuring fast load times and dynamic data handling</li>
                    <li>Implementing efficient state management for high-performance applications</li>
                    </Typography>
                </ContentBox>
                <ContentBox style={{width: "850px", height: "380px"}}>
                <Typography style={{display: "flex", flexDirection: "column", padding: "20px", fontSize: "16px", fontWeight: "500",}}>
                    <span style={{fontWeight: "700", fontSize: "18px"}}>Full Stack Devleopment</span>
                    From single-page applications (SPAs) to complex enterprise systems, our full stack
                    development approach is all about creating products that meet your business
                    objectives and exceed expectations.
                    <br></br>
                    <br></br>
                    <br></br>
                    <span style={{fontWeight: "700", fontSize: "18px"}}>Our Expertise Includes:</span>
                    <li>Integration of frontend and backend technologies to build scalable web architectures</li>
                    <li>Tailored solutions that align with your specific business requirements</li>
                    <li>Continuous delivery and deployment, ensuring rapid go-to-market times</li>
                    </Typography>
                </ContentBox>
            </ContentDiv>
        </div>
    )
};

export default SdeContent;