import SearchIcon from "@mui/icons-material/Search";
import LoginIcon from '@mui/icons-material/Login';
import CallIcon from '@mui/icons-material/Call';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import TerminalIcon from '@mui/icons-material/Terminal';
import AssistantIcon from '@mui/icons-material/Assistant';
import CloudIcon from '@mui/icons-material/Cloud';
import {
  AppBar,
  Box,
  Button,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import React
 from "react";
import { theme } from "../theme";
import NoaxsLogo from "../images/noaxsLogo.png"

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const NavButtons = styled(Button)(({theme}) => ({
    color: theme.palette.otherColor.main,
    fontWeight: "700",
    textTransform: 'none',
}));

const Navbar = ({raiRef, softwareRef, aiRef, cloudRef, productRef, solutionRef, setOpenContactDialog}) => {
    const scrollToRef = (ref) => ref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  return (
    <AppBar position="sticky" color="primary" style={{ height: "100px"}}>
      <StyledToolbar>
        <div style={{display: "flex", flexDirection: "row"}}>
        <div style={{backgroundImage: `url(${NoaxsLogo})`, height: "70px", width: "80px", marginTop: "15px"}}></div>
        <Typography variant="h4" sx={{ display: { xs: "none", sm: "block" },   fontSize: "60px",
        fontWeight: "700", color: theme.palette.otherColor.main, marginTop: "10px" }}>
          Noaxs
        </Typography>
        </div>
        <Box sx={{display: "flex",}}>
        <NavButtons variant="text"
         onClick={ () => scrollToRef(raiRef) } style={{ marginRight: "20px", fontSize: "15px", marginTop: "15px"}}>
            <SmartToyOutlinedIcon sx={{paddingX: 1, height: "30px", width: "30px"}}/>
                RAi
        </NavButtons>
        <NavButtons variant="text"
         onClick={ () => scrollToRef(softwareRef) } style={{ marginRight: "20px", fontSize: "15px", marginTop: "15px"}}>
            <TerminalIcon sx={{paddingX: 1, height: "30px", width: "30px"}}/>
                Software Development
        </NavButtons>
        <NavButtons variant="text"
         onClick={ () => scrollToRef(aiRef) } style={{ marginRight: "20px", fontSize: "15px", marginTop: "15px"}}>
            <AssistantIcon sx={{paddingX: 1, height: "30px", width: "30px"}}/>
                Ai Integrations
        </NavButtons>
        <NavButtons variant="text"
         onClick={ () => scrollToRef(cloudRef) } style={{ marginRight: "20px", fontSize: "15px", marginTop: "15px"}}>
            <CloudIcon sx={{paddingX: 1, height: "30px", width: "30px"}}/>
                Cloud & Devops
        </NavButtons>
        <NavButtons variant="text"
         onClick={ () => scrollToRef(productRef) } style={{ marginRight: "20px", fontSize: "15px", marginTop: "15px"}}>
            <EngineeringOutlinedIcon sx={{paddingX: 1, height: "30px", width: "30px"}}/>
                Product Engineering
        </NavButtons>
        <NavButtons variant="text"
         onClick={ () => scrollToRef(solutionRef) } style={{ marginRight: "20px", fontSize: "15px", marginTop: "15px"}}>
            <EmojiObjectsIcon sx={{paddingX: 1, height: "30px", width: "30px"}}/>
                Solutions
        </NavButtons>
        <NavButtons variant="text" style={{ marginRight: "20px", fontSize: "15px", marginTop: "15px"}}>
            <SearchIcon sx={{paddingX: 1, height: "30px", width: "30px"}}/>
                Search
        </NavButtons>
        <NavButtons variant="text" 
        href="https://rai.noaxs.ai" style={{ marginRight: "20px", fontSize: "15px", marginTop: "15px"}}>
            <LoginIcon sx={{paddingX: 1, height: "30px", width: "30px"}}/>
                Login
        </NavButtons>
        <NavButtons variant="text" 
        onClick={ () => setOpenContactDialog(true) }style={{ marginRight: "20px", fontSize: "15px", marginTop: "15px"}}>
            <CallIcon sx={{paddingX: 1, height: "30px", width: "30px"}}/>
                Contact
        </NavButtons>
        </Box>
      </StyledToolbar>
    </AppBar>
  );
};

export default Navbar;