import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette:{
    primary:{
      main: "#FFFFFF",
      light: "#D03D56"
    },
    secondary:{
      main: '#15c630',
    },
    otherColor:{
      main:"#800020"
    }
  }
});