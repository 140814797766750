import { Box, Button, Typography } from "@mui/material";
import AssistantIcon from '@mui/icons-material/Assistant';
import aiRobo from "../../images/aiRobo.png"
import styled from "@emotion/styled";

const AiIntegrationContent = () => {

    const ContentBox = styled(Box)(({theme}) => ({
        textTransform: 'none',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "30px",
        borderRadius: "30px",
        boxShadow: "0 4px 8px black",
        backgroundColor: "white",
        color: "#520c61",
        width: "800px",
        height: "300px",
        [theme.breakpoints.up('md')]: {
            flexDirection: "row",
            marginRight: "30px",
            marginLeft: "30px"
        },
    }));

    const KeyFeaturesBox = styled(Box)(({theme}) => ({
        textTransform: 'none',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "30px",
        borderRadius: "30px",
        boxShadow: "0 4px 8px black",
        backgroundColor: "#ead1dc",
        color: "black",
        width: "96%",
        height: "260px",
        [theme.breakpoints.up('md')]: {
            flexDirection: "row",
            marginRight: "30px",
            marginLeft: "30px",
        },
    }));

    const ContentDiv = styled('div')(({theme}) => ({
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up('md')]: {
            flexDirection: "row",

        },
    }));
    return (
        <div>
            <ContentDiv id="rai-content-box-1">
            <ContentBox style={{ height: "400px", width: "600px"}}>
            <Box
        id="Rai"
        sx={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            scrollMarginTop: "70px",
            width: "500px"
        }}>
            <Typography style={{
                padding: "10px",
                fontSize: "70px",
                fontWeight: "700",
                display: "flex",
                textAlign: "center",
                color: "#520c61",
                lineHeight: "80px"
            }}>
                <div>
                    <AssistantIcon style={{width: "80px", height: "80px", marginBottom: "-20px", marginRight: "20px"}} />
                AI
                <br></br>
                Integrations
                </div>
            </Typography>
        </Box>
            </ContentBox>
            <ContentBox style={{ marginTop: "50px"}}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "90vh",
                    textAlign: "center",
                }}>
                <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                }}
                >
            <Typography sx={{
                    fontSize: "20px",
                    fontWeight: "600",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "20px",
                }}
                >
                <div sx={{ display: "flex", alignItems: "center" }}>
                Unlock the potential of artificial intelligence with bespoke
                solutions. 
                <br></br>
                <br></br>
                Our AI Integration services are designed to
                enhance your operations, provide insightful analytics, and
                improve user interactions, ensuring you stay ahead in
                today’s fast-paced digital landscape.
                </div>
                </Typography>
                </div>
            </div>
            </ContentBox>
            <div style={{ height: "320px", width: "800px", display: "flex", justifyContent: "center"}}>
                <div style={{
                    marginTop: "50px",
                    backgroundImage: `url(${aiRobo})`,
                    height: "320px",
                    width: "500px"
                }}>

                </div>
            </div>
            </ContentDiv>
            <ContentDiv>
                <ContentBox style={{width: "850px", height: "380px"}}>
                    <Typography style={{display: "flex", flexDirection: "column", padding: "20px", fontSize: "20px", fontWeight: "500"}}>
                    <span style={{fontWeight: "700", fontSize: "24px"}}>Custom AI Solutions</span>
                    <br></br>
                    From automating routine tasks to solving complex problems, our custom AI
                    solutions are designed to integrate seamlessly into your existing systems,
                    enhancing efficiency and scalability. Our approach involves understanding your
                    challenges deeply and deploying sophisticated AI technologies to craft solutions
                    that are as unique as your business.
                    </Typography>
                </ContentBox>
                <ContentBox style={{width: "850px", height: "380px"}}>
                <Typography style={{display: "flex", flexDirection: "column", padding: "20px", fontSize: "20px", fontWeight: "500"}}>
                    <span style={{fontWeight: "700", fontSize: "24px"}}>Predictive Analytics</span>
                    <br></br>
                    By harnessing the latest in machine learning and data processing technologies, we
                    analyze historical data to forecast future trends and behaviors. This insight allows
                    your business to anticipate market changes, optimize operations, and enhance
                    strategic decision-making, giving you a competitive edge.
                    </Typography>
                </ContentBox>
                <ContentBox style={{width: "850px", height: "380px"}}>
                <Typography style={{display: "flex", flexDirection: "column", padding: "20px", fontSize: "20px", fontWeight: "500"}}>
                    <span style={{fontWeight: "700", fontSize: "24px"}}>AI Assistants</span>
                    <br></br>
                    Enhance your customer engagement with our AI-powered assistants. Whether it&#39;s
                    through chatbots for instant customer support or virtual assistants for
                    streamlined user interaction, our AI solutions are designed to improve service
                    quality and efficiency. These intelligent systems learn from interactions to provide
                    personalized experiences, increasing customer satisfaction and loyalty.
                    </Typography>
                </ContentBox>
            </ContentDiv>
        </div>
    )
};

export default AiIntegrationContent;