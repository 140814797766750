import React from "react";
import { useRef } from "react";
import SmartOutdoorSolution from "./smartOutdoorSolution";
import styled from "@emotion/styled";
import { Button, Typography, Box } from "@mui/material";
import BoltIcon from '@mui/icons-material/Bolt';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import ParkNx from "./parkNx";

const SolutionsContent = () => {
    const smartRef = useRef(null);
    const parkRef = useRef(null);

    const scrollToRef = (ref) => ref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

    const ContentBox = styled(Box)(({theme}) => ({
        textTransform: 'none',
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: "30px",
        borderRadius: "30px",
        boxShadow: "0 4px 8px black",
        backgroundColor: "#FFFFFF",
        color: "black",
        width: "800px",
        height: "300px",
        [theme.breakpoints.up('md')]: {
            flexDirection: "column",
            width: "800px",
            height: "340px",
            marginRight: "30px",
            marginLeft: "30px",
        },
    }));

    const StyledBox = styled(Button)(({theme}) => ({
        textTransform: 'none',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "60px",
        borderRadius: "30px",
        boxShadow: "0 4px 8px #800020",
        '&:hover': {
            backgroundColor: 'lightblue', // Change this to your desired hover color
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: "column",
            width: "900px",
            height: "340px",
            marginRight: "30px",
            marginTop: "60px",
            marginLeft: "30px",
        },
    }));

    const BoxContent = styled(Typography)(({theme}) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        fontWeight: "800",
        color: theme.palette.otherColor.main,
        [theme.breakpoints.up('md')]: {
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
        },
    }));


    return (
        <div>
            <div style={{ display: "flex",
        flexDirection: "row",
        marginBottom: "80px"
        }}>
            <StyledBox style={{ backgroundColor: "#F8F8FF", height: "540px" }}
            onClick={ () => scrollToRef(smartRef) }>
                <BoxContent style={{ color: "#228B22", fontSize: "80px", fontWeight: "700"}}>
                <EmojiObjectsIcon sx={{height: "100px", width: "100px", 
                marginRight: "10px", 
                color: "#228B22"}}/>
                Solutions
                </BoxContent>
            </StyledBox>
            <StyledBox style={{ backgroundColor: "#B8E8D2", height: "340px", marginTop: "160px" }}
            onClick={ () => scrollToRef(smartRef) }>
                <BoxContent style={{ textColor: "#2b3bff", fontSize: "35px"}}>
                <BoltIcon style={{height: "140px", width: "140px", color: "#f28500"}} />Smart Outdoor Power Management
                </BoxContent>
            </StyledBox>
            <StyledBox style={{ backgroundColor: "#B8E8D2", marginTop: "160px"}}
            onClick={ () => scrollToRef(parkRef) }>
            <BoxContent style={{fontSize: "45px"}}>
            <DriveEtaIcon style={{height: "100px", width: "100px", color: "#f28500"}} />
                <LocalParkingIcon style={{height: "100px", width: "100px", color: "#f28500", marginRight: "10px"}} />ParkNx
                </BoxContent>
            </StyledBox>

            </div>
            <div style={{marginTop: "180px", scrollMarginTop: "210px"}}
            ref={smartRef}>
                <SmartOutdoorSolution/>
            </div>
            <div style={{marginTop: "80px", scrollMarginTop: "180px",}} ref={parkRef}>
                <ParkNx />
            </div>
        </div>
    );
};

export default SolutionsContent;