import React from "react";
import { Typography, Box } from "@mui/material";
import styled from "@emotion/styled";
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';

const ProductEngineeringContent = () => {

    const localStyles = {
        container: {
            display: "flex",
            flexDirection: "column",
            height: "100vh",
        },
        header: {
           height: "70px", fontWeight: "700",
        },
        content: {
            fontSize: "20px",
            textAlign: "justify",
            marginTop: "10px"
        }
    }
    const ContentBox = styled(Box)(({theme}) => ({
        textTransform: 'none',
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: "30px",
        borderRadius: "30px",
        boxShadow: "0 4px 8px black",
        backgroundColor: "#FFFFFF",
        color: "#000073",
        width: "800px",
        height: "300px",
        [theme.breakpoints.up('md')]: {
            flexDirection: "column",
            width: "800px",
            height: "340px",
            marginRight: "30px",
            marginLeft: "30px",
        },
    }));

        const ContentTypography = styled(Typography)(({theme}) => ({
            fontSize: "30px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    padding: "20px 20px 20px 20px",
                    color: "#000073",
            fontSize: '24px',
        }));
    return (
        <div style={{marginTop: "50px", width: "90%", display: "flex", flexDirection: "column", justifyContent: "center"}}>
        <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
        }}>
            <ContentBox style={{
                height: "400px",
                width: "600px",
                display: "flex",
                justifyContent: "center"}}>
            <Box
        id="Product Engineering"
        sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            scrollMarginTop: "70px",
            width: "500px"
        }}>
                            <div>
            <Typography style={{
                color: "#000073",
                display: "flex",
                //justifyContent: "center",
                //alignItems: "center",
                textAlign: "center",
                fontSize: "70px",
                fontWeight: "700",
            }}>
                <span>
            <EngineeringOutlinedIcon sx={{height: "100px", width: "100px", marginRight: "10px", marginBottom: "-20px", color: "#000073"}}/>Product Engineering</span>
            </Typography>
            </div>
        </Box>
            </ContentBox>
            <ContentBox>
            <ContentTypography>
                <div style={localStyles.header}>Innovative Product Engineering Solutions</div>
                <span style={localStyles.content}>Welcome to the forefront of Product Engineering, where your ideas transform into market-leading innovations. 
                We specialize in Mechanical Engineering, Electronics, and Embedded Systems, offering tailored solutions that 
                push the boundaries of technology and design.</span>
                </ContentTypography>
                </ContentBox>
                <ContentBox>
                <ContentTypography>
                <strong style={localStyles.header}>Mechanical Engineering: Building the Backbone of Your Products</strong>
                <span style={localStyles.content}>Our Mechanical Engineering services lay the foundation for robust and reliable products. From CAD modeling 
                and simulation to thermal analysis and material selection, we handle all aspects of mechanical design and development. 
                We ensure that every product is not only functional but also optimized for manufacturing efficiency and product longevity.</span>
                </ContentTypography>
                </ContentBox>
        </div>
        <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px"
            }}>
            <ContentBox>
                <ContentTypography>
                <strong style={localStyles.header}>Electronics Engineering: Powering Progress with Precision</strong>
                <span style={localStyles.content}>Dive into the core of your products with our Electronics Engineering expertise. We design and develop electronic systems 
                that are compact, efficient, and high-performing. From schematic design to PCB layout and prototype testing, our comprehensive 
                services ensure your products lead the market in innovation and quality.</span>
                </ContentTypography>
                </ContentBox>
                <ContentBox>
                <ContentTypography>
                <strong style={localStyles.header}>Embedded Systems Engineering: Smart Solutions for Intelligent Products</strong>
                <span style={localStyles.content}>Elevate your product’s intelligence with our Embedded Systems Engineering. We integrate sophisticated software and hardware 
                to deliver seamless functionality and superior performance. Whether it’s wearable tech, smart home devices, or 
                industrial automation, our embedded solutions are at the heart of modern smart products.</span>
                </ContentTypography>
                </ContentBox>
                <ContentBox>
                <ContentTypography>
                <strong style={localStyles.header}>Partner with Us for Engineering Excellence</strong>
                <span style={localStyles.content}>Join us as we redefine what’s possible in product engineering. Our expert team is ready to transform your challenges into opportunities, 
                driving your product success from concept through to production. Connect with us today to discuss how we can support your next engineering 
                breakthrough. <bold>Contact Us</bold></span>
                </ContentTypography>
                </ContentBox>
        </div>
        </div>
    )
};

export default ProductEngineeringContent;