import { Box, Button, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import { theme } from "../../theme";
import WavingHandIcon from '@mui/icons-material/WavingHand';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';

const RaiContent = () => {

    const [ raiShowMore, setRaiShowMore ] = useState(false);
    const [ recruitmentShowMore, setRecruitmentShowMore ] = useState(false);

    const ContentBox = styled(Box)(({theme}) => ({
        textTransform: 'none',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "30px",
        borderRadius: "30px",
        boxShadow: "0 4px 8px black",
        backgroundColor: "white",
        color: "#985102",
        width: "800px",
        height: "300px",
        [theme.breakpoints.up('md')]: {
            flexDirection: "row",
            marginRight: "30px",
            marginLeft: "30px"
        },
    }));

    const KeyFeaturesBox = styled(Box)(({theme}) => ({
        textTransform: 'none',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "30px",
        borderRadius: "30px",
        boxShadow: "0 4px 8px black",
        backgroundColor: "white",
        color: "#985102",
        width: "96%",
        height: "260px",
        [theme.breakpoints.up('md')]: {
            flexDirection: "row",
            marginRight: "30px",
            marginLeft: "30px",
        },
    }));

    const ContentDiv = styled('div')(({theme}) => ({
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up('md')]: {
            flexDirection: "row",

        },
    }));
    return (
        <div>
            <ContentDiv id="rai-content-box-1">
            <ContentBox style={{ height: "400px", width: "600px"}}>
            <Box
        id="Rai"
        sx={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            scrollMarginTop: "70px",
        }}>
            <SmartToyOutlinedIcon sx={{height: "100px", width: "100px", marginRight: "20px", color: "#985102"}}/>
            <Typography variant="h1" style={{
                color: "#985102",
                fontSize: "90px",
                fontWeight: "700",
            }}>
                RAi
            </Typography>
        </Box>
            </ContentBox>
            <ContentBox style={{ marginTop: "50px"}}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "90vh",
                    textAlign: "center",
                }}>
                <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                }}
                >
            <Typography sx={{
                    fontSize: "30px",
                    fontWeight: "700",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "20px",
                }}
                >
                <div sx={{ display: "flex", alignItems: "center" }}>
                    Hi! <WavingHandIcon sx={{ color: "#F6BE00", marginRight: "10px" }}/>
                    This is <span style={{ color: "#520c61"}}>RAi</span>.
                </div>
                <span style={{
                    fontWeight: "500",
                    fontSize: "25px"
                }}>I help recruiters achieve exponential productivity.</span>
                </Typography>
                { !raiShowMore && <Button 
                variant="text"
                style={{
                    color: theme.palette.otherColor.main,
                    fontWeight: "700",
                    fontSize: "18px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                    textTransform: "none",
                    '&:hover': {
                        color: "pink"
                    }
                }}
                onClick={ () => { 
                    setRaiShowMore(true);
                 } }>
                    Know more about me
                </Button>
                }
                </div>
                { raiShowMore && (
                <Typography variant="body1" 
                style={{
                    padding: "0px 20px 20px 20px",
                    fontWeight: "500",
                    fontSize: "18px"
                }}>
                
                In today's fast-paced world, recruitment is about connecting talent and opportunity with precision and 
                speed. RAi, our state-of-the-art SAAS application, 
                empowers recruiters to achieve exponential productivity gains by seamlessly bridging the gap between 
                hiring intent and the intrinsic value of a profile. 
                <Button 
                style={{
                    color: "blue",
                    textTransform: "none"
                }}
                onClick={ () => setRaiShowMore(false) }
                >show less</Button>
                </Typography>
                )}
            </div>
            </ContentBox>
            <ContentBox style={{ marginTop: "50px"}}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "90vh",
                    textAlign: "center",
                }}>
                <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                }}
                >
            <Typography sx={{
                    fontSize: "30px",
                    fontWeight: "700",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "20px",
                }}
                >
                <div sx={{ display: "flex", alignItems: "center" }}>
                Empower Your Recruitment with Cutting-Edge AI
                </div>
                </Typography>
                { !recruitmentShowMore && <Button 
                variant="text"
                style={{
                    color: theme.palette.otherColor.main,
                    fontWeight: "700",
                    fontSize: "18px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "underline",
                    textTransform: "none",
                    '&:hover': {
                        color: "pink"
                    }
                }}
                onClick={ () => { 
                    setRecruitmentShowMore(true);
                 } }>
                    Want to know How?
                </Button>
                }
                </div>
                { recruitmentShowMore && (
                <Typography variant="body1" 
                style={{
                    padding: "0px 20px 20px 20px",
                    fontWeight: "500",
                    fontSize: 'clamp(12px, 18px, 24px)'
                }}>
                RAi leverages advanced learning systems designed to understand and predict the nuanced requirements
                of both recruiters and candidates. RAi synthesizes vast amounts of data to identify the best matches, 
                ensuring that you’re not just filling positions, but are making strategic hiring decisions that align 
                with your long-term business goals.
                <Button 
                style={{
                    color: "blue",
                    textTransform: "none"
                }}
                onClick={ () => setRecruitmentShowMore(false) }
                >show less</Button>
                </Typography>
                )}
            </div>
            </ContentBox>
            </ContentDiv>
            <KeyFeaturesBox style={{ marginTop: "90px"}}>
            <Typography sx={{
                    fontSize:'clamp(12px, 2vw, 24px)',
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    padding: "20px 20px 20px 20px",
                }}>

                <span style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "30px",
                    fontWeight: "700",
                    marginBottom: "30px"
                    }}>Key Features</span>
                    <div
                     style={{
                        fontSize: 'clamp(12px, 18px, 24px)',
                        fontWeight: "300",
                        display: "flex",
                        flexDirection: { s: "column", md: "row"},
                        alignItems: "flex-start",
                     }}>
                        <div style={{
                            width: "25%",
                            height: "25%",
                            display: "flex",
                    alignItems: "center",
                        }}>
                            <Typography sx={{
                    fontSize:'clamp(12px, 18px, 24px)',
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                }}>
                    <span style={{ fontSize: "24px", fontWeight: "600"}}>Predictive Matching</span>
                    Utilize AI-driven algorithms that forecast fitment success beyond the traditional recruitment metrics.
                </Typography>
                        </div>
                        <div style={{
                            width: "25%",
                            height: "25%",
                            display: "flex",
                            alignItems: "center",
                        }}>
                            <Typography sx={{
                    fontSize:'clamp(12px, 18px, 24px)',
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "10px",
                }}>
                    <span style={{ fontSize: "24px", fontWeight: "600"}}>Dynamic Learning</span>
                    Our system continuously learns from recruitment outcomes, improving its predictive capabilities over time.
                </Typography>
                    </div>
                    <div style={{
                            width: "25%",
                            height: "25%",
                            display: "flex",
                            alignItems: "center",
                        }}>
                            <Typography sx={{
                    fontSize:'clamp(12px, 18px, 24px)',
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "10px",
                }}>
                    <span style={{ fontSize: "24px", fontWeight: "600"}}>Intuitive Interface</span>
                    Streamline your recruitment process with a user-friendly platform designed for efficiency and ease of use.
                </Typography>
                    </div>
                    <div style={{
                            width: "25%",
                            height: "25%",
                            display: "flex",
                            alignItems: "center",
                        }}>
                            <Typography sx={{
                    fontSize:'clamp(12px, 18px, 24px)',
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "10px",
                }}>
                    <span style={{ fontSize: "24px", fontWeight: "600"}}>Comprehensive Analytics</span>
                    Gain insights from robust analytics to fine-tune your recruitment strategies and decision-making processes.
                </Typography>
                    </div>
                    </div>
                </Typography>
                </KeyFeaturesBox>
        </div>
    )
};

export default RaiContent;