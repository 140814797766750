import { Typography } from "@mui/material";
import React from "react";
import {Box} from "@mui/material";
import styled from "@emotion/styled";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';

const ParkNx = () => {
    const localStyles = {
        parkNxHeading: {
            fontSize: "80px",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            marginBottom: "10px",
            color: "#d03800",
            justifyContent: "center"
        },
        contentContainer: {
            display: "flex",
            flexDirection: "row",
            marginTop: "50px"
        },
        questionBox: {
            borderRadius: "30px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
        },
        questionIcon: {
            width: "90px",
            height: "90px",
            marginTop: "120px",
            marginLeft: "20px",
            color: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        solutionContainer: {
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "center",
        },
        solutionContent: {
            marginLeft: "20px"
        }
    };

    const QuestionBox = styled(Box)(({theme}) => ({
        textTransform: 'none',
        display: "flex",
        alignItems: "center",
        marginBottom: "20px",
        //borderRadius: "30px",
        border: "1px solid black",
        backgroundColor: "#fedcdb",
        color: "black",
        width: "90%",
        [theme.breakpoints.up('md')]: {
            flexDirection: "column",
            width: "65%",
            height: "320px",
            //marginRight: "30px",
            marginLeft: "50px",
            boxShadow: "0 4px 8px black",
            //height: "540px",
        },
    }));

    const BenefitsBox = styled(Box)(({theme}) => ({
        textTransform: 'none',
        display: "flex",
        //flexDirection: "row",
        justifyContent: "flex-start",
        // alignItems: "center",
        width: "95%",
        marginLeft: "8px",
        border: "1px solid black",
        boxShadow: "0 4px 8px black",
        backgroundColor: "#d7f5d3",
        color: "black",
        marginBottom: "20px",
        marginTop: "30px",
        padding: "10px, 10px, 10px, 10px"
    }));

    const ContentBox = styled(Box)(({theme}) => ({
        textTransform: 'none',
        display: "flex",
        alignItems: "center",
        marginBottom: "30px",
        borderRadius: "30px",
        backgroundColor: "#FFFFFF",
        color: "black",
        width: "50%",
        [theme.breakpoints.up('md')]: {
            flexDirection: "column",
            width: "85%",
            height: "350px",
            display: "flex",
            justifyContent: "center",
            marginRight: "30px",
            marginLeft: "30px",
            //height: "540px",
        },
    }));

    return (
        <div>
            <div style={{
                display: "flex",
                flexDirection: "row"
            }}>
            <QuestionBox style={{height: "440px", display: "flex", justifyContent: "center", backgroundColor: "#F8F8FF"}}>
            <Typography style={localStyles.parkNxHeading}>
                    ParkNx
                </Typography>
            </QuestionBox>
            <div style={localStyles.contentContainer}>
                <QuestionBox style={{ width: "450px"}}>
                    <Box style={localStyles.questionBox}>
                    <Typography style={{
                         fontSize: "20px",
                         display: "flex",
                         padding: "10px"
                         }}>
                            <div style={{display: "flex", flexDirection: "column", marginTop: "10px"}}>
                            <li>Are you finding it challenging to ensure that only designated employees are 
                            using your company’s allocated parking spaces <span style={{ fontSize: "20px", fontWeight: "700", color: "red"}}>?</span></li>
                            <li style={{ marginTop: "10px"}}>
                            Would you like to remove the hassle of constantly monitoring your parking area <span style={{ fontSize: "20px", fontWeight: "700", color: "red"}}>?</span>
                            </li>   
                            </div>      
                            </Typography>
                    <QuestionMarkIcon style={localStyles.questionIcon} />
                    </Box>
                </QuestionBox>
            </div>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "50px"}}>
            <ContentBox>
                    <Typography>
                        <div style={localStyles.solutionContainer}>
                            <div style={{fontSize: "24px", display: "flex", flexDirection: "row"}}>
                                <span style={{fontWeight: "700", color: "#d03800"}}>ParkNx </span>&nbsp;is here to help! 
                                <InsertEmoticonIcon style={{ 
                                    color: "green",
                                    width: "30px",
                                    height: "30px",
                                    marginLeft: "5px",
                                    marginTop: "2px"
                                    }}/>
                            </div>
                            <div style={{fontSize: "20px", display: "flex", textAlign: "center"}}>
                            Our cutting-edge parking solution leverages the latest technology to optimize 
                            your corporate parking management, ensuring a smoother and more enjoyable parking 
                            experience for your employees.
                            </div>
                            <ArrowDownwardIcon style={{ marginTop: "10px", height: "60px", width: "60px", color: "green"}}/>
                        </div>
                    </Typography>
                </ContentBox>
            </div>
            </div>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <BenefitsBox>
            <Typography style={localStyles.solutionContent}>
                        <div style={{display: "flex", flexDirection: "column", padding: "10px"}}>
                            <div style={{display: "flex", flexDirection: "row", marginBottom: "10px"}}>
                                <DoneAllOutlinedIcon style={{color: "green", marginRight: "5px",}}/>
                                <Typography>
                                <strong>Improved Employee Satisfaction:</strong> By ensuring that assigned parking spaces are always available, 
                                our solution can help improve employee satisfaction and reduce parking-related stress.
                                </Typography>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", marginBottom: "10px"}}>
                                <DoneAllOutlinedIcon style={{color: "green", marginRight: "5px",}}/>
                                <Typography>
                                <strong>Increased Efficiency:</strong> Our solution ensures that only authorized vehicles are parked in assigned spaces,
                                 reducing congestion and improving parking lot efficiency. By monitoring parking usage in real time, you can optimize your 
                                 parking infrastructure and reduce waste, saving time and money.
                                </Typography>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", marginBottom: "10px"}}>
                                <DoneAllOutlinedIcon style={{color: "green", marginRight: "5px",}}/>
                                <Typography>
                                <strong>Data analytics:</strong> Our solution collects data on parking usage, which can be used to optimize parking lot 
                                design and management.
                                </Typography>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", marginBottom: "10px"}}>
                                <DoneAllOutlinedIcon style={{color: "green", marginRight: "5px",}}/>
                                <Typography>
                                <strong>Easy installation:</strong> Our solution can be installed quickly and easily, with minimal disruption to your business.
                                </Typography>
                            </div>
                        </div>
                        </Typography>
                </BenefitsBox>
            </div>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <BenefitsBox style={{ display: "flex", justifyContent: "center", textAlign: "center", backgroundColor: "#FDFDD2"}}>
                    <Typography style={{ textAlign: "center", fontSize: "22px"}}>
                    Is your corporate campus struggling with parking headaches? 
                    <span style={{color: "green", fontWeight: "700"}}> Reach out to us for a solution!</span>
                    </Typography>
                </BenefitsBox>
            </div>
        </div>
    );
};

export default ParkNx;